<template>
	<div class="transfer">

	</div>
</template>

<script>
export default {
	mounted() {
		window.location.href = "/";
	},
};
</script>

